import './main';
import './title';
import './sass/main.scss';
import './avatar.css';
import Avatar from './images/avatar.jpg';

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
            console.log('SW registered: ', registration);
        }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}

new BullshitJobTitleGenerator({ el: document.getElementById('title') });

const img = document.getElementById('avatar');
img.src = Avatar;